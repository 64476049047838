import { Api } from "../../services/Api";
import CartService from "../../services/CartService";
import Cart from "./Cart";

export default () => ({
    adding: false,
    qty: 1,
    purchasableId: "",
    purchasables: {},
    cart: {},
    /*
    This Expects:
    {
        0: {
            id: {{purcashableId}},
            qty: 1
        }
    }
    */

    init() {
        window.addEventListener("cartUpdate", (event) => {
            this.cart = event.detail;
        });
        window.addEventListener("addToCart", () => {
            this.qty = 1;
            Object.keys(this.purchasables).forEach((e) => {
                this.purchasables[e].qty = 1;
            });
        });
    },

    async addToCart() {
        try {
            this.adding = true;
            const data = {
                purchasableId: this.purchasableId,
                qty: this.qty,
            };
            const res = await Api.post(
                "/actions/commerce/cart/update-cart",
                data
            );
            if (res.data.errors) {
                // TODO:
                return;
            }
            await CartService.getCart(this);
            this.$dispatch("addToCart", data);
            this.adding = false;
        } catch (e) {
            this.adding = false;
            if (
                e?.response?.data?.errors &&
                e.response.data.errors["lineItems.0.qty"]
            ) {
                alert(e.response.data.errors["lineItems.0.qty"][0]);
                return;
            }
            console.error(e);
        }
    },

    async addToCartAgain(item) {
        try {
            const options = {};
            Object.entries(item.options).forEach(([key, value]) => {
                if (
                    ["importId", "giftCardTransaction", "giftCard"].indexOf(
                        key
                    ) > -1
                )
                    return;
                options[key] = value;
            });
            this.adding = true;
            const data = {
                purchasableId: item.purchasableId,
                qty: item.qty,
                options,
            };
            const res = await Api.post(
                "/actions/commerce/cart/update-cart",
                data
            );
            if (res.data.errors) {
                // TODO:
                return;
            }
            await CartService.getCart(this);
            this.$dispatch("addToCart", data);
            this.adding = false;
        } catch (e) {
            this.adding = false;
            if (
                e?.response?.data?.errors &&
                e.response.data.errors["lineItems.0.qty"]
            ) {
                alert(e.response.data.errors["lineItems.0.qty"][0]);
                return;
            }
            console.error(e);
        }
    },
});
