let lightbox = document.createElement("div");

/**
 *		CLOSE LIGHTBOX
 *		- Fired on click, touchend, and keyup
 *		- Only applies if lightbox has "aria-hidden" attribute value of 'false' AND (escape key is pressed OR (close button is clicked/touched or Enter key is pressed while focused))
 *		- Sets "aria-hidden" attribute value to 'true'
 */
function closeLightbox(event) {
	if (dragging) return;

	var target = event.target.closest(".rd-lightbox-close");
	if (lightbox.getAttribute("aria-hidden") == "false" && ((event.key == "Escape" || event.key == "Esc") || (target && (event.type == "click" || event.type == "touchend" || (event.type == "keyup" && event.key == "Enter"))))) {
		event.preventDefault();

		lightbox.querySelector(".content .gallery").innerHTML = "";

		lightbox.setAttribute("aria-hidden", "true");
		lightbox.removeAttribute("data-active-gallery");
	}
}

/**
 *		OPEN LIGHTBOX
 *		- Add slides and count to lightbox, display
 */
function openLightbox(event) {
	if (dragging) return;

	var target = event.target.closest("[data-lightbox]");
	if (target) {
		event.preventDefault();

		lightbox.setAttribute("aria-hidden", "false");

		// Which gallery are we displaying?
		var galleryID = target.getAttribute("data-lightbox");
		lightbox.setAttribute("data-active-gallery", galleryID);

		// Add the slides
		lightbox.querySelector(".content .gallery").innerHTML = "";
		var galleryItems = document.querySelectorAll("[data-lightbox=" + galleryID + "]");
		for (var i = 0; i < galleryItems.length; i++) {
			var slide = document.createElement("div");
			slide.setAttribute("data-slide", i + 1);
			// YouTube, Vimeo, or local video link?
			if (galleryItems[i].getAttribute("href").indexOf("youtu") !== -1 || galleryItems[i].getAttribute("href").indexOf("vimeo") !== -1 || galleryItems[i].getAttribute("href").indexOf(".mp4") !== -1 || galleryItems[i].getAttribute("href").indexOf(".m4p") !== -1 || galleryItems[i].getAttribute("href").indexOf(".m4v") !== -1 || galleryItems[i].getAttribute("href").indexOf(".ogg") !== -1 || galleryItems[i].getAttribute("href").indexOf(".ogv") !== -1 || galleryItems[i].getAttribute("href").indexOf(".webm") !== -1) {
				var videoInfo = galleryItems[i].getAttribute("href").match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

				slide.classList.add("video");
				if (videoInfo && videoInfo[3].indexOf('youtu') > -1) {
					slide.classList.add("youtube");
					slide.innerHTML = '<div class="embed-container">' +
							'<iframe width="640" height="360" src="https://www.youtube.com/embed/' + videoInfo[6] + '?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' +
						'</div>';
				} else
					if (videoInfo && videoInfo[3].indexOf('vimeo') > -1) {
					slide.classList.add("vimeo");
					slide.innerHTML = '<div class="embed-container">' +
							'<iframe src="https://player.vimeo.com/video/' + videoInfo[6] + '?&autoplay=1&autopause=0" width="640" height="360" frameborder="0" allow="autoplay" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
						'</div>';
				} else {
					slide.classList.add("local");
					// Get file extension
                    var fileExtension = galleryItems[i].getAttribute("href").split(".").pop();
                    var queryString = fileExtension.split("?").pop();
                    if (queryString) {
                        fileExtension = fileExtension.replace('?' + queryString, '');
                    }
					if (fileExtension == "mp4" || fileExtension == "m4p" || fileExtension == "m4v") {
						var type = 'mp4';
					} else if (fileExtension == "ogg" || fileExtension == "ogv") {
						var type = 'ogg';
					} else if (fileExtension == "webm") {
						var type = 'webm';
					}
					slide.innerHTML = '<div class="embed-container">' +
							'<video controls>' +
								'<source src="' + galleryItems[i].getAttribute("href") + '" type="video/' + type + '" /> ' +
							'</video>' +
						'</div>';
				}
			} else
			// Assume an image
			{
				var alt = "";
				if (galleryItems[i].querySelector("img") && galleryItems[i].querySelector("img").hasAttribute("alt")) {
					alt = galleryItems[i].querySelector("img").getAttribute("alt");
				}
				slide.innerHTML = '<img src="' + galleryItems[i].getAttribute("href") + '" alt="' + alt + '" />';

				// Alt tag populated? Add caption
				if (alt) {
					slide.innerHTML += '<div class="caption">' + alt + '</div>';
				}
			}

			// Is this the item that triggered the lightbox?
			if (target.getAttribute("href") == galleryItems[i].getAttribute("href")) {
				slide.classList.add("active");
				var activeSlide = slide;
				lightbox.querySelector(".toolbar .pages .current").innerText = i + 1;
			}

			// Add to gallery
			lightbox.querySelector(".content .gallery").appendChild(slide);
		}

		// Set the total count
		lightbox.querySelector(".content").setAttribute("data-count", galleryItems.length);
		lightbox.querySelector(".toolbar .pages .total").innerText = galleryItems.length;

		// Finally, show the lightbox and focus on the close button
		lightbox.setAttribute("aria-hidden", "false");
		lightbox.querySelector(".rd-lightbox-close").focus();

		// Once shown, if active slide contains a video play it
		if (activeSlide.classList.contains("video")) {
			playVideo(activeSlide);
		}
	}
}

/**
 *		PLAY VIDEO ON CHANGE TO SLIDE
 */
function playVideo(slide) {
	var iframe = slide.querySelector("iframe");
	if (slide.classList.contains("youtube")) {
		iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'playVideo' }), '*');
	} else
	if (slide.classList.contains("vimeo")) {
		iframe.contentWindow.postMessage(JSON.stringify({ method: 'play' }), '*');
	} else {
		slide.querySelector("video").play();
	}
}


/**
 *		PAUSE VIDEO ON CHANGE FROM SLIDE
 */
function pauseVideo(slide) {
	var iframe = slide.querySelector("iframe");
	if (slide.classList.contains("youtube")) {
		iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo' }), '*');
	} else
	if (slide.classList.contains("vimeo")) {
		iframe.contentWindow.postMessage(JSON.stringify({ method: 'pause' }), '*');
	} else {
		slide.querySelector("video").pause();
	}
}

/**
 *		PREV SLIDE
 */
function prevSlide() {
	var activeSlide = lightbox.querySelector(".gallery .active");
	if (activeSlide.classList.contains("video")) {
		pauseVideo(activeSlide);
	}
	activeSlide.classList.remove("active");
	var prevSibling = activeSlide.previousElementSibling;
	if (prevSibling) {
		// If there is a prev slide, make it active
		prevSibling.classList.add("active");
		if (prevSibling.classList.contains("video")) {
			playVideo(prevSibling);
		}
		lightbox.querySelector(".toolbar .pages .current").innerText = prevSibling.getAttribute("data-slide");
	} else {
		// Otherwise, make the last slide active
		var slides = lightbox.querySelectorAll(".gallery > div");
		slides[slides.length - 1].classList.add("active");
		if (slides[slides.length - 1].classList.contains("video")) {
			playVideo(slides[slides.length - 1]);
		}
		lightbox.querySelector(".toolbar .pages .current").innerText = slides.length;
	}
}
function prevSlideTrigger(event) {
	if (lightbox.getAttribute("aria-hidden") == "false") {
		// Trigger on arrow left key
		if (event.key == "ArrowLeft" || event.key == "Left") {
			prevSlide();
		}

		// Trigger if swiping right
		var target = event.target.closest(".rd-lightbox .gallery");
		if (target && swipeDirection == "right") {
			prevSlide();
		}

		// Otherwise, return if dragging and trigger if clicking/touching the previous link
		if (dragging) return;
		var target = event.target.closest(".rd-lightbox-prev");
		if (target) {
			event.preventDefault();
			prevSlide();
		}
	}
}

/**
 *		NEXT SLIDE
 */
function nextSlide() {
	var activeSlide = lightbox.querySelector(".gallery .active");
	if (activeSlide.classList.contains("video")) {
		pauseVideo(activeSlide);
	}
	activeSlide.classList.remove("active");
	var nextSibling = activeSlide.nextElementSibling;
	if (nextSibling) {
		// If there is a next slide, make it active
		nextSibling.classList.add("active");
		if (nextSibling.classList.contains("video")) {
			playVideo(nextSibling);
		}
		lightbox.querySelector(".toolbar .pages .current").innerText = nextSibling.getAttribute("data-slide");
	} else {
		// Otherwise, make the first slide active
		lightbox.querySelector(".gallery > div").classList.add("active");
		if (lightbox.querySelector(".gallery > div").classList.contains("video")) {
			playVideo(lightbox.querySelector(".gallery > div"));
		}
		lightbox.querySelector(".toolbar .pages .current").innerText = "1";
	}
}
function nextSlideTrigger(event) {
	if (lightbox.getAttribute("aria-hidden") == "false") {
		// Trigger on arrow right key
		if (event.key == "ArrowRight" || event.key == "Right") {
			nextSlide();
		}

		// Trigger if swiping right
		var target = event.target.closest(".rd-lightbox .gallery");
		if (target && swipeDirection == "left") {
			nextSlide();
		}

		// Otherwise, return if dragging and trigger if clicking/touching the previous link
		if (dragging) return;
		var target = event.target.closest(".rd-lightbox-next");
		if (target) {
			event.preventDefault();
			nextSlide();
		}
	}
}

document.addEventListener("DOMContentLoaded", function () {
    /**
     *		- Add lightbox to DOM
     *		- Open and close lightbox eventListeners
     *		- Advance to previous/next slide on click/touch of links or left/right swipe
     */
    lightbox.classList.add("rd-lightbox");
    lightbox.setAttribute("aria-hidden", "true");
    lightbox.innerHTML = '<div class="toolbar">' +
        '<div class="pages">' +
        '<span class="current"></span>' +
        '<span class="sep">/</span>' +
        '<span class="total"></span>' +
        '</div>' +
        '<div class="links">' +
        '<a href="#" class="rd-lightbox-close" aria-label="Close Lightbox"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg></a>' +
        '</div>' +
        '</div > ' +
        '<div class="content">' +
        '<a href="#" class="rd-lightbox-prev" aria-label="Previous Slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z"/></svg></a>' +
        '<div class="gallery"></div>' +
        '<a href="#" class="rd-lightbox-next" aria-label="Next Slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></a>' +
        '</div > ';
    document.body.appendChild(lightbox);
});

// Close lightbox on click, touchend, or Escape key
document.addEventListener("click", closeLightbox);
document.addEventListener("touchend", closeLightbox);
document.addEventListener("keyup", closeLightbox);

// Open lightbox when clicking a link with a data-lightbox attribute
document.addEventListener("click", openLightbox);
document.addEventListener("touchend", openLightbox);

// Advance to previous slide
document.addEventListener("click", prevSlideTrigger);
document.addEventListener("touchend", prevSlideTrigger);
document.addEventListener("keyup", prevSlideTrigger);

// Advance to next slide
document.addEventListener("click", nextSlideTrigger);
document.addEventListener("touchend", nextSlideTrigger);
document.addEventListener("keyup", nextSlideTrigger);