// Keep track of whether user is dragging or clicking
window.dragging = false;
window.swipeDirection, window.clientX, (window.clientY = "");

/**
 *		- Determine if touch device is moving to prevent unwanted click/touch events
 */
document.addEventListener("touchmove", function (event) {
    dragging = true;

    var diffX,
        diffY = 0;
    diffX = event.touches[0].clientX - window.clientX;
    diffY = event.touches[0].clientY - window.clientY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX < 0) {
            window.swipeDirection = "left";
        } else {
            window.swipeDirection = "right";
        }
    } else {
        if (diffY < 0) {
            window.swipeDirection = "up";
        } else {
            window.swipeDirection = "down";
        }
    }
    if (!event.touches.length) {
        return;
    }
    window.clientX = event.touches[0].clientX;
    window.clientY = event.touches[0].clientY;
});
document.addEventListener("touchstart", function (event) {
    dragging = false;
    window.swipeDirection = "";
    if (!event.touches.length) {
        return;
    }
    window.clientX = event.touches[0].clientX;
    window.clientY = event.touches[0].clientY;
});
