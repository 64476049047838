export default () => ({
    itemId: "",
    variant: {},
    product: {},

    init() {
        window.addEventListener("cartUpdate", (event) => {
            this.refreshLineItem();
        });
    },

    refreshLineItem() {
        const item = this.cart.lineItems.find((item) => item.id == this.itemId);
        if (!item) {
            return;
        }
        this.variant = item.purchasable;
        this.product = item.purchasable ? item.purchasable.product : null;
    },
});
