import CartService from "../../services/CartService";

export default () => ({
    cartSlideIsOpen: false,
    hasFetchedLineItems: false,
    isFetchingLineItems: false,
    cart: {},
    cartCount: 0,
    removing: [],
    updating: [],

    init() {
        window.addEventListener("addToCart", () => {
            this.cartSlideIsOpen = false;
        });
        window.addEventListener("cartUpdate", (event) => {
            const queryParams = new URLSearchParams(window.location.search);
            let number = queryParams.get("number");
            if (window.location.pathname.indexOf("/account/orders/") > -1) {
                number = window.location.pathname.split("/").pop();
            }

            if (event.detail.number == number) {
                return;
            }

            this.cart = event.detail;
            this.isFetchingLineItems = false;
            this.hasFetchedLineItems = true;
            this.cartCount = 0;
            if ("lineItems" in this.cart) {
                this.cart.lineItems.forEach((item) => {
                    this.cartCount += item.qty;
                });
            }
        });
        this.fetchCart();
    },

    openCartSlider() {
        this.cartSlideIsOpen = true;
        this.fetchCart();
    },

    fetchCart() {
        if (!this.hasFetchedLineItems) {
            CartService.getCart(this);
            this.isFetchingLineItems = true;
        }
    },

    async updateQuantities(lineItemId) {
        try {
            this.updating.push(lineItemId);

            const lineItem = this.cart.lineItems.filter(
                (li) => li.id == lineItemId
            )[0];

            await CartService.updateLineItem(this, lineItemId, lineItem.qty);
        } catch (e) {}
        this.updating = this.updating.filter((u) => u != lineItemId);
    },

    async removeLineItem(lineItemId) {
        try {
            this.removing.push(lineItemId);
            await CartService.removeLineItem(this, lineItemId);
            var lineItems = document.querySelector(".line-item-" + lineItemId);
            if (lineItems) {
                lineItems.forEach((e) => e.remove());
            }
        } catch (e) {
            console.error(e);
            this.removing = this.removing.filter((i) => i != lineItemId);
        }
    },
});
