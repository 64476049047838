import { Api } from "../../services/Api";

export default () => ({
    formData: {
        couponCode: "",
    },
    formMessage: "",
    formError: "",
    formLoading: false,
    buttonText: "Apply",
    init() {
        window.addEventListener("cartUpdate", (event) => {
            const queryParams = new URLSearchParams(window.location.search);
            let number = queryParams.get("number");
            if (window.location.pathname.indexOf("/account/orders/") > -1) {
                number = window.location.pathname.split("/").pop();
            }

            if (number && event.detail.number != number) {
                return;
            }

            this.cart = event.detail;
        });
    },
    reset() {
        this.formMessage = "";
        this.formError = "";
        this.formLoading = false;
        this.buttonText = "Apply";
    },
    async submitForm() {
        if (this.formLoading) {
            return;
        }
        this.reset();
        this.formLoading = true;
        this.buttonText = "Applying...";
        try {
            const res = await Api.post(
                "/actions/commerce/cart/update-cart",
                this.formData
            );
            if (res.data.errors && res.data.errors.couponCode) {
                this.reset();
                this.formError = res.data.errors.couponCode;
                return;
            }
            window.location.reload();
        } catch (e) {
            this.reset();
            this.formError = "Something went wrong.";
        }
    },
});
