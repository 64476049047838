import CartService from "../../services/CartService";

export default () => ({
    gatewaysUpdating: [],
    paymentsUpdating: [],

    async updatePaymentMethod(gatewayId) {
        try {
            this.gatewaysUpdating.push(gatewayId);
            await CartService.updateCartPaymentGateway(this, gatewayId);
            location.reload();
        } catch (e) {
            console.log(e);
        }
    },

    async updatePaymentMethodViaSource(paymentSourceId) {
        try {
            this.paymentsUpdating.push(paymentSourceId);
            await CartService.updateCartPaymentSourceId(this, paymentSourceId);
            location.reload();
        } catch (e) {
            console.log(e);
        }
    },

    async removePaymentSource(paymentSourceId) {
        try {
            if (
                confirm("Are you sure you want to remove this payment method?")
            ) {
                this.paymentsUpdating.push(paymentSourceId);
                await CartService.removePaymentSource(this, paymentSourceId);
                location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    },
});
