function updateActionOnSubmit(form) {
    // var actionableSelect = form.querySelector('select[data-filter-type="action"]');
    // if (actionableSelect.options[actionableSelect.selectedIndex].getAttribute("data-uri")) {
    //     // form.setAttribute("action", actionableSelect.options[actionableSelect.selectedIndex].getAttribute("data-uri"));
    //     // actionableSelect.removeAttribute("name");
    // }

    // // console.log("action", form.getAttribute("action"))
    form.submit();
}

var filterBarSelects = document.querySelectorAll('.filter-bar select');
for (let index = 0; index < filterBarSelects.length; index++) {
    filterBarSelects[index].addEventListener("change", function () {
        updateActionOnSubmit(filterBarSelects[index].closest("form"));
    });
}

var filterBarInputs = document.querySelectorAll('.filter-bar input');
for (let index = 0; index < filterBarInputs.length; index++) {
    filterBarInputs[index].addEventListener("change", function () {
        updateActionOnSubmit(filterBarInputs[index].closest("form"));
    });
}