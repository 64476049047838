import { Api } from "../../services/Api";
import CartService from "../../services/CartService";

export default () => ({
    pypModalOpen: false,
    pypSaving: false,
    bundleLimit: 1,
    eligibleProducts: [],
    options: {},
    lineItemOptions: {},
    images: {},
    lineItemId: "",
    purchasableId: "",
    hoverId: "",
    hoverSummaries: {},
    hoverImages: {},
    selectedProductIds: [],
    unselectedProductIds: [],
    pypModalInit(
        eligibleProducts,
        bundleLimit,
        purchasableId,
        lineItemId,
        lineItemOptions,
        lineItemQty = null
    ) {
        this.eligibleProducts = eligibleProducts;
        this.bundleLimit = bundleLimit;
        this.purchasableId = purchasableId;
        this.lineItemId = lineItemId;
        this.lineItemOptions = lineItemOptions;
        this.resetOptions();
        setTimeout(() => {
            const searchParams = new URLSearchParams(window.location.search);

            if (lineItemId) {
                this.pypModalOpen = true;
                if (lineItemOptions && lineItemOptions.bundleSelections) {
                    this.resetOptions();
                    Object.entries(lineItemOptions.bundleSelections).forEach(
                        ([id, amount]) => {
                            this.options[id] = amount;
                            // Add ids in selectedProductIds for each amount
                            for (var x = 0; x < amount; x++) {
                                this.selectedProductIds.push(id);
                            }
                        }
                    );
                    this.rebuildSelectedProducts();
                }
            } else if (window.location.search && searchParams.get("options")) {
                // Handle confirm selections from buy again in order history
                this.resetOptions();
                try {
                    const qty = parseInt(searchParams.get("qty"));
                    this.qty = qty;
                    if (isNaN(qty)) {
                        this.qty = 1;
                    } else if (qty > 500 || qty < 1) {
                        this.qty = 1;
                    }
                } catch (e) {
                    this.qty = 1;
                    console.error(e);
                }
                try {
                    // base64 decode json string via options query param
                    const options = searchParams.get("options");
                    if (options) {
                        const decoded = atob(options);
                        const parsed = JSON.parse(decoded);
                        Object.entries(parsed).forEach(([id, amount]) => {
                            let isEligible = this.eligibleProducts.find(
                                (p) => p.id == id
                            );

                            if (!isEligible) {
                                return;
                            }

                            this.options[id] = amount;
                            // Add ids in selectedProductIds for each amount
                            for (var x = 0; x < amount; x++) {
                                this.selectedProductIds.push(id);
                            }
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                this.pypModalOpen = true;
                this.rebuildSelectedProducts();
            }
        }, 300);

        if (lineItemQty) {
            this.qty = parseInt(lineItemQty + "");
        }
    },

    resetOptions() {
        this.options = {};
        this.eligibleProducts.forEach((p) => (this.options[p.id] = 0));
        this.rebuildSelectedProducts();
    },

    rebuildSelectedProducts() {
        this.unselectedProductIds.length = 0;

        for (
            var x = 0;
            x < this.bundleLimit - this.selectedProductIds.length;
            x++
        ) {
            this.unselectedProductIds.push(0);
        }
    },
    changePurchasable(purchasableId, qty) {
        let selectedAmount = 0;

        Object.entries(this.options).forEach(([id, amount]) => {
            selectedAmount += amount;
        });

        if (!this.options[purchasableId]) {
            this.options[purchasableId] = 0;
        }

        if (qty < 0 && this.options[purchasableId] >= Math.abs(qty)) {
            this.options[purchasableId] += qty;

            // Remove the last entry matching the purchasableId
            for (var x = this.selectedProductIds.length - 1; x >= 0; x--) {
                if (this.selectedProductIds[x] == purchasableId) {
                    this.selectedProductIds.splice(x, 1);
                    break;
                }
            }
        }

        if (qty > 0) {
            // If the bundle limit is 1, we infer the qty from the selected pieces.
            // Otherwise, we should enforce not going of the bundle limit.
            if (this.bundleLimit !== 1) {
                if (selectedAmount + qty > this.bundleLimit) {
                    return;
                }
            }

            this.options[purchasableId] += qty;
            this.selectedProductIds.push(purchasableId);
        }
        this.rebuildSelectedProducts();
    },

    onQtyChange(variantId) {
        this.options[variantId] = parseInt(this.options[variantId]);
    },

    async addToCart($dialog) {
        let selectedAmount = 0;

        Object.entries(this.options).forEach(([id, amount]) => {
            selectedAmount += amount;
        });

        if (selectedAmount < this.bundleLimit) {
            alert("Please pick all available pieces to fill your box.");
            return;
        }

        try {
            let qty = this.qty;

            // If the bundle limit is 1, we infer the qty from the selected pieces.
            // Otherwise, we should use the qty input from the PDP.
            if (this.bundleLimit == 1) {
                qty = selectedAmount;
            }

            let options = {
                bundleSelections: {},
            };
            Object.entries(this.options).forEach(([id, amount]) => {
                if (amount > 0) {
                    options.bundleSelections[id] = amount;
                }
            });
            this.pypSaving = true;

            let data = {
                purchasableId: this.purchasableId,
                qty,
                options,
            };

            if (this.lineItemId) {
                data = {
                    lineItems: {
                        [this.lineItemId]: {
                            options,
                            qty,
                        },
                    },
                };
            }

            const res = await Api.post(
                "/actions/commerce/cart/update-cart",
                data
            );
            if (res.data.errors) {
                if (res.data.errors["lineItems.0.qty"])
                    alert(res.data.errors["lineItems.0.qty"][0]);
                return;
            }

            if (this.lineItemId) {
                window.location.href = "/shop/cart";
                return;
            }

            await CartService.getCart(this);
            this.$dispatch("addToCart", {
                purchasableId: this.purchasableId,
                qty: this.qty,
            });
            this.pypSaving = false;
            // this.pypModalOpen = false;
            $dialog.close();
            this.resetOptions();
        } catch (e) {
            this.pypSaving = false;
            if (
                e?.response?.data?.errors &&
                e.response.data.errors["lineItems.0.qty"]
            ) {
                alert(e.response.data.errors["lineItems.0.qty"][0]);
                return;
            }
            console.error(e);
        }
    },
});
