import { Api } from "../../services/Api";

export default () => ({
    email: "",
    undoSkipEmail() {
        this.email = "";
    },
    skipEmail() {
        if (
            !confirm(
                "Are you sure you want to skip adding an email? The only way we can reach you will be through your phone number."
            )
        ) {
            return;
        }
        Api.post("/actions/debrand/cart/skip-email")
            .then((response) => {
                if (response.data.success) {
                    this.email = response.data.email;
                } else {
                    alert(
                        "There was an error while trying to skip the email. Please reload the page and try again."
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                alert(
                    "There was an error while trying to skip the email. Please reload the page and try again."
                );
            });
    },
});
