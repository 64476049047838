import iodine from "@caneara/iodine";
const Iodine = new iodine();

export const validate = (
    el,
    { value, expression, modifiers },
    { Alpine, evaluateLater, evaluate, effect, cleanup }
) => {
    // In this case we don't evaluate the expression
    const rules = JSON.parse(expression || "{}");

    const handleBlur = () => {
        console.log(typeof el.value, el.value, rules);
        const error = Iodine.assert(el.value, rules);

        el.setCustomValidity(error.error);
        el.reportValidity();

        // It's valid - not sure if we can use magics ($dispatch) in a directive plugin
        el.dispatchEvent(
            new CustomEvent("valid", {
                detail: {},
                bubbles: false,
            })
        );
    };

    // If the user is "inputting" then remove the error state
    const handleInput = () => el.setCustomValidity("");

    el.addEventListener("input", handleInput);
    el.addEventListener("blur", handleBlur);

    // Be sure you clean up your listeners as a best practice
    cleanup(() => {
        el.removeEventListener("input", handleInput);
        el.removeEventListener("blur", handleBlur);
    });
};
