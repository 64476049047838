import { Api } from "../../services/Api";

export default () => ({
    giftCardBalanceOpen: false,
    giftCardBalanceForm: {},
    init() {
        this.reset();
    },

    reset() {
        this.formMessage = "";
        this.formError = "";
        this.formLoading = false;
        this.buttonText = "Check";
    },

    async submitForm() {
        if (this.formLoading) {
            return;
        }
        this.reset();
        this.formLoading = true;
        this.buttonText = "Checking...";
        try {
            const res = await Api.post(
                "/actions/redfire-giftcards/gift-card/check-balance",
                this.giftCardBalanceForm
            );
            if (res.data.errors && res.data.errors.giftCardNumber) {
                this.reset();
                this.formError = res.data.errors.giftCardNumber;
                return;
            }

            if (res.data.error) {
                this.reset();
                this.formError = res.data.error;
                return;
            }

            this.reset();

            const formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });

            this.formMessage = `Current Balance: ${formatter.format(
                res.data.amount
            )}`;
        } catch (e) {
            console.error(e);
            this.reset();
            this.formError = "Something went wrong.";
        }
    },
});
