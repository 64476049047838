export default () => ({
    cartPopupIsOpen: false,
    cart: {},
    removing: [],
    updating: [],

    filterLineItemId: "",

    init() {
        window.addEventListener("cartUpdate", (event) => {
            this.cart = event.detail;
        });
        window.addEventListener("addToCart", (event) => {
            // Find item by most recently updated date
            const lineItems = this.cart.lineItems
                .filter((a) => a.sku != this.cart.iceBrickSku)
                .filter((a) => event.detail.purchasableId == a.purchasableId);

            if (!lineItems.length) return;

            this.filterLineItemId = lineItems[0].id;
            this.cartPopupIsOpen = true;

            setTimeout(() => {
                if (this.filterLineItemId == lineItems[0].id) {
                    this.cartPopupIsOpen = false;
                }
            }, 5000);
        });
    },
});
