export default () => ({
    addressRecommendationModalOpen: false,

    errors: {},

    addressSaving: false,

    hasAccountRecommendation: false,
    accountRecommendation: null,
    originalAccountAddress: null,
    useAccountRecommendation: false,

    hasBillingRecommendation: false,
    billingRecommendation: null,
    originalBillingAddress: null,
    useBillingRecommendation: false,

    hasShippingRecommendation: false,
    shippingRecommendation: null,
    originalShippingAddress: null,
    useShippingRecommendation: false,

    billingAddressSameAsShipping: false,
    shippingAddressSameAsBilling: false,

    keys: [
        "shippingRecommendation",
        "billingRecommendation",
        "accountRecommendation",
    ],

    // Invalid Addresses
    accountAddressInvalid: false,
    billingAddressInvalid: false,
    shippingAddressInvalid: false,

    useInvalidAccountAddress: false,
    useInvalidBillingAddress: false,
    useInvalidShippingAddress: false,

    init() {
        window.addEventListener("addressError", (event) => {
            this.parseErrors(event.detail.errors);
        });
        window.addEventListener("addressSaving", (event) => {
            this.addressSaving = event.detail.addressSaving;
        });
        window.addEventListener("invalid-address", (event) => {
            this.openModal();
            switch (event.detail.addressType) {
                case "":
                    this.accountAddressInvalid = true;
                    break;
                case "billingAddress":
                    this.billingAddressInvalid = true;
                    break;
                case "shippingAddress":
                    this.shippingAddressInvalid = true;
                    break;
            }
        });
    },

    parseErrors(errors) {
        this.errors = errors;
        this.hasAccountRecommendation = false;
        this.hasShippingRecommendation = false;
        this.hasBillingRecommendation = false;

        this.accountRecommendation = null;
        this.billingRecommendation = null;
        this.shippingRecommendation = null;

        this.originalBillingAddress = this.getAddressFields(
            document.querySelector(".js-address-fieldset.BillingAddress")
        );

        this.originalShippingAddress = this.getAddressFields(
            document.querySelector(".js-address-fieldset.ShippingAddress")
        );

        this.originalAccountAddress = this.getAddressFields(
            document.querySelector(".account.editAddresses.js-address-fieldset")
        );

        if (
            errors &&
            errors["billingAddress.recommendation"] &&
            errors["billingAddress.recommendation"].length > 0
        ) {
            this.hasBillingRecommendation = true;
            this.useBillingRecommendation = true;
            this.billingRecommendation = JSON.parse(
                errors["billingAddress.recommendation"][0]
            );

            this.billingAddressSameAsShipping =
                document.querySelector(
                    "input[name='billingAddressSameAsShipping']"
                )?.checked ?? false;
            this.shippingAddressSameAsBilling =
                document.querySelector(
                    "input[name='shippingAddressSameAsBilling']"
                )?.checked ?? false;
        }
        if (
            errors &&
            errors["shippingAddress.recommendation"] &&
            errors["shippingAddress.recommendation"].length > 0
        ) {
            this.hasShippingRecommendation = true;
            this.useShippingRecommendation = true;
            this.shippingRecommendation = JSON.parse(
                errors["shippingAddress.recommendation"][0]
            );

            this.billingAddressSameAsShipping =
                document.querySelector(
                    "input[name='billingAddressSameAsShipping']"
                )?.checked ?? false;
            this.shippingAddressSameAsBilling =
                document.querySelector(
                    "input[name='shippingAddressSameAsBilling']"
                )?.checked ?? false;
        }
        if (
            errors &&
            errors["recommendation"] &&
            errors["recommendation"].length > 0
        ) {
            this.hasAccountRecommendation = true;
            this.useAccountRecommendation = true;
            this.accountRecommendation = JSON.parse(
                errors["recommendation"][0]
            );
        }

        if (
            this.hasAccountRecommendation ||
            this.hasBillingRecommendation ||
            this.hasShippingRecommendation
        ) {
            this.openModal();
        }
    },

    getAddressFields(addressBox) {
        if (addressBox === null) {
            console.warn("Address Box is null");
            return null;
        }

        let _businessNameContainer = addressBox.querySelector(
            ".organization input"
        );
        let _address1Field = addressBox.querySelector(".address-line-1 input");
        let _address2Field = addressBox.querySelector(".address-line-2 input");
        let _postalField = addressBox.querySelector(".postalCode input");
        let _cityField = addressBox.querySelector(".locality input");
        let _stateFieldInput = addressBox.querySelector(
            ".administrative-area input"
        );
        let _stateFieldSelect = addressBox.querySelector(
            ".administrative-area select"
        );
        let _countryField = addressBox.querySelector(".country-code select");
        let _businessField = addressBox.querySelector(
            ".isBusinessAddress select"
        );

        return {
            addressLine1: _address1Field?.value,
            addressLine2: _address2Field?.value,
            locality: _cityField?.value,
            administrativeArea: _stateFieldInput
                ? _stateFieldInput?.value
                : _stateFieldSelect?.value,
            postalCode: _postalField?.value,
            countryCode: _countryField?.value,
            isBusinessAddress: _businessField?.value,
        };
    },

    openModal() {
        this.addressRecommendationModalOpen = true;

        var addressModal = document.querySelector(
            ".modal-wrapper.address-recommendation-modal-wrapper .modal"
        );
        if (addressModal) {
            addressModal.addEventListener("scroll", function () {
                document.activeElement.blur();
            });
        }
    },

    fillAddress(
        addressBoxSelector,
        addressName,
        useRecommendation,
        recommendation,
        originalAddress
    ) {
        let addressBox = document.querySelector(addressBoxSelector);
        if (addressBox === null) {
            console.warn("Address Box is null");
            return;
        }

        let _businessNameContainer = addressBox.querySelector(
            ".organization input"
        );

        let _address1Field = addressBox.querySelector(".address-line-1 input");
        let _address2Field = addressBox.querySelector(".address-line-2 input");
        let _postalField = addressBox.querySelector(".postalCode input");
        let _cityField = addressBox.querySelector(".locality input");
        let _countryField = addressBox.querySelector(".country-code select");
        let _stateFieldInput = addressBox.querySelector(
            ".administrative-area input"
        );
        let _stateFieldSelect = addressBox.querySelector(
            ".administrative-area select"
        );
        let _businessField = addressBox.querySelector(
            ".isBusinessAddress select"
        );

        if (!useRecommendation) {
            // set hidden input to true with name automaticAddressValidation, add if not exists
            let form = _address1Field.closest("form");

            let automaticAddressValidation = form.querySelector(
                "input[name='automaticAddressValidation']"
            );
            if (!automaticAddressValidation) {
                automaticAddressValidation = document.createElement("input");
                form.appendChild(automaticAddressValidation);
            }
            automaticAddressValidation.type = "hidden";
            automaticAddressValidation.name = addressName
                ? `${addressName}[fields][automaticAddressValidation]`
                : "fields[automaticAddressValidation]";
            automaticAddressValidation.value = "0";
            return;
        }

        if (recommendation.addressLine1) {
            _address1Field.value = recommendation.addressLine1;
        }

        if (recommendation.addressLine2) {
            _address2Field.value = recommendation.addressLine2;
        }

        if (recommendation.postalCode) {
            _postalField.value = recommendation.postalCode;
        }

        if (recommendation.locality) {
            _cityField.value = recommendation.locality;
        }

        if (recommendation.administrativeArea) {
            if (_stateFieldInput) {
                _stateFieldInput.value = recommendation.administrativeArea;
            } else {
                _stateFieldSelect.value = recommendation.administrativeArea;
            }
        }

        if (recommendation.countryCode) {
            _countryField.value = recommendation.countryCode;
        }

        // TODO:
        // if (recommendation.isBusinessAddress) {
        //     _businessField.checked = recommendation.isBusinessAddress;
        // }
    },

    async save($dialog) {
        // Apply Changes to the form

        if (this.hasAccountRecommendation) {
            this.fillAddress(
                ".account.editAddresses.js-address-fieldset",
                "",
                this.useAccountRecommendation,
                this.accountRecommendation,
                this.originalAccountAddress
            );
        }

        if (this.hasBillingRecommendation) {
            this.fillAddress(
                ".js-address-fieldset.BillingAddress",
                "billingAddress",
                this.useBillingRecommendation,
                this.billingRecommendation,
                this.originalBillingAddress
            );
        }

        if (this.hasShippingRecommendation) {
            this.fillAddress(
                ".js-address-fieldset.ShippingAddress",
                "shippingAddress",
                this.useShippingRecommendation,
                this.shippingRecommendation,
                this.originalShippingAddress
            );
        }

        let shouldSubmit = true;

        if (this.accountAddressInvalid) {
            if (this.useInvalidAccountAddress) {
                this.handleInvalidAddresses(
                    ".account.editAddresses.js-address-fieldset",
                    ""
                );
            } else {
                shouldSubmit = false;
            }
        }

        if (this.billingAddressInvalid) {
            if (this.useInvalidBillingAddress) {
                this.handleInvalidAddresses(
                    ".js-address-fieldset.BillingAddress",
                    "billingAddress"
                );
            } else {
                shouldSubmit = false;
            }
        }

        if (this.shippingAddressInvalid) {
            if (this.useInvalidShippingAddress) {
                this.handleInvalidAddresses(
                    ".js-address-fieldset.ShippingAddress",
                    "shippingAddress"
                );
            } else {
                shouldSubmit = false;
            }
        }

        $dialog.close();

        if (shouldSubmit) {
            this.$dispatch("saveAddress");
        }
    },

    handleInvalidAddresses(addressBoxSelector, addressName) {
        let addressBox = document.querySelector(addressBoxSelector);
        let _address1Field = addressBox.querySelector(".address-line-1 input");

        let form = _address1Field.closest("form");

        let automaticAddressValidation = form.querySelector(
            "input[name='automaticAddressValidation']"
        );
        if (!automaticAddressValidation) {
            automaticAddressValidation = document.createElement("input");
            form.appendChild(automaticAddressValidation);
        }
        automaticAddressValidation.type = "hidden";
        automaticAddressValidation.name = addressName
            ? `${addressName}[fields][automaticAddressValidation]`
            : "fields[automaticAddressValidation]";
        automaticAddressValidation.value = "0";
    },
});
