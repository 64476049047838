/**
 *		- Close the current site-wide alert when clicking/touching the close button
 *		- Sets/updates a browser cookie with the current alert ID
 */
function closeSitewideAlert(event) {
    if (dragging) return;

    var target = event.target.closest("[data-alert-id]");
    if (target) {
        event.preventDefault();

        // Get existing cookie value
        let cookies = document.cookie.split(";");
        cookies.forEach((el, i) => {
            var cookie = el.split("=");
            cookies[cookie[0].trim()] = cookie[1];
        });

        // Urgent alerts return after 24 hours, all others after 7 days
        if (target.getAttribute("data-alert-type") == "urgent") {
            document.cookie =
                "alertsUrgent=" +
                (cookies["alertsUrgent"] ? cookies["alertsUrgent"] + "|" : "") +
                target.getAttribute("data-alert-id") +
                "; max-age=86400; path=/; secure=true; samesite";
        } else {
            document.cookie =
                "alertsOther=" +
                (cookies["alertsOther"] ? cookies["alertsOther"] + "|" : "") +
                target.getAttribute("data-alert-id") +
                "; max-age=604800; path=/; secure=true; samesite";
        }

        target.parentElement.remove();
    }
}

document.addEventListener("click", closeSitewideAlert);
document.addEventListener("touchend", closeSitewideAlert);
