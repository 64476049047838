/**
 *		TOGGLE MENU
 *		- Toggle main navigation on mobile resolutions (lower than breakpointToDesktopNav)
 */
function toggleMenu(event) {
	if (dragging) return;

	var target = event.target.closest('.toggleMenu');
	if (target) {
		event.preventDefault();
		event.stopPropagation();

		target.classList.toggle("on");
		var siteMenu = document.querySelector(".siteNav .siteMenu");
		if (window.getComputedStyle(siteMenu).display == "none") {
			siteMenu.style.display = "block";
		} else {
			siteMenu.style.display = "none";
		}
	}
}

/**
 *		TOGGLE CHILD MENUS
 *		- Toggle child navigation on mobile resolutions (lower than breakpointToDesktopNav)
 */
function toggleChildMenu(event) {
	if (dragging) return;

	var target = event.target.closest(".toggleChild");
	if (target) {
		event.preventDefault();
		event.stopPropagation();

		target.parentNode.classList.toggle("on");
		var siblingMenu = target.nextElementSibling;
		if (window.getComputedStyle(siblingMenu).display == "none") {
			siblingMenu.style.display = "block";
		} else {
			siblingMenu.style.display = "none";
		}
	}
}

/**
 *		TOGGLE DESKTOP CHILD MENUS
 *		- Prevent link click on Enter keydown
 *		- Open child menu on first touch or Enter keyup, follow link only if child menu is open
 */
function toggleDesktopChildMenu(event) {
	if (dragging) return;

	if (window.widthREMs >= window.breakpointToDesktopNav) {
		var target = event.target.closest(".siteMenu > ul > li > a");
		if (target && event.type == "keydown" && event.key == "Enter") {
			event.preventDefault();
		} else
		if (target && (event.type == "touchend" || (event.type == "keyup" && event.key == "Enter"))) {
			event.stopPropagation();

			// Prevent default on anchor tag on first touch or Enter key
			if (!target.hasAttribute("data-is-open") || target.getAttribute("data-is-open") == "false") {
				event.preventDefault();

				// Hide other child navs, but not if they're an ancestor
				var menusWithChildren = document.querySelectorAll(".siteMenu > ul > li");
				for (var i = 0; i < menusWithChildren.length; i++) {
					if (!menusWithChildren[i].contains(target)) {
						menusWithChildren[i].classList.remove("on");
                        if(menusWithChildren[i].querySelector(".childNav")) {
						    menusWithChildren[i].querySelector(".childNav").style.display = "none";
                        }
						var link = menusWithChildren[i].querySelector(".siteMenu > ul > li > a");
						if (link) {
							link.setAttribute("data-is-open", false);
						}
					}
				}

				// Display child navigation on first touch or Enter key
				target.setAttribute("data-is-open", true);
				target.parentNode.classList.toggle("on");
                if(target.parentNode.querySelector(".childNav")) {
                    target.parentNode.querySelector(".childNav").style.display = "block";
                }
			} else {
				target.setAttribute("data-is-open", false);
				target.click();
			}
		}
	}
}

/**
 *		HEADER / NAV
 *		- Toggle navigation by click/touch on mobile
 *		- Toggle navigation by mouse enter/leave on desktop
 */
// Mobile: Main navigation
document.addEventListener("click", toggleMenu);
document.addEventListener("touchend", toggleMenu);

// Mobile: Child navigation
document.addEventListener("click", toggleChildMenu);
document.addEventListener("touchend", toggleChildMenu);

// Desktop: Close all open child menus on touchend
document.addEventListener("touchend", function (event) {
    if (window.widthREMs >= window.breakpointToDesktopNav) {
        var target = event.target.closest(".siteMenu > ul > li > a");
        // If target is direct child of li.has-children, then follow link
        if (target && !target.parentNode.classList.contains("has-children")) {
        } else
        // Otherwise, stopPropagation and close all menus
        if (!target) {
            event.stopPropagation();
            var linksInMenusWithChildren = document.querySelectorAll(".siteMenu > ul > li > a");
            for (var i = 0; i < linksInMenusWithChildren.length; i++) {
                linksInMenusWithChildren[i].setAttribute("data-is-open", false);
                linksInMenusWithChildren[i].parentNode.classList.remove("on");
                if(linksInMenusWithChildren[i].parentNode.querySelector(".childNav")) {
                    linksInMenusWithChildren[i].parentNode.querySelector(".childNav").style.display = "none";
                }
            }
        }
    }
});

// Desktop: Open child menu on first touch or Enter keyup, follow link only if child menu is open
document.addEventListener("touchend", toggleDesktopChildMenu);
document.addEventListener("keydown", toggleDesktopChildMenu);
document.addEventListener("keyup", toggleDesktopChildMenu);

document.addEventListener("DOMContentLoaded", function () {
	// Desktop: Display child menu when mouse enters, and hide when mouse leaves
	var menusWithChildren = document.querySelectorAll(".siteMenu > ul > li");
	for (var i = 0; i < menusWithChildren.length; i++) {
		// Show on enter
		menusWithChildren[i].addEventListener("mouseenter", function (event) {
            if (window.widthREMs >= window.breakpointToDesktopNav) {
                event.target.classList.add("on");
                if(event.target.querySelector(".childNav")) {
                    event.target.querySelector(".childNav").style.display = "block";
                }
			}
		});
		// Hide on leave
		menusWithChildren[i].addEventListener("mouseleave", function (event) {
			if (window.widthREMs >= window.breakpointToDesktopNav) {
				event.target.classList.remove("on");
                if(event.target.querySelector(".childNav")) {
				    event.target.querySelector(".childNav").style.display = "none";
                }
			}
		});
	}
});

// Toggle search
function toggleSearch(event) {
    if(dragging) return;

    var target = event.target.closest('.toggleSearch');
    if(target) {
        event.preventDefault();
        event.stopPropagation();

        var searchForm = document.querySelector('.siteHeader .toggleSearch + form');
        searchForm.classList.toggle('active');
        if(searchForm.classList.contains('active')) {
            searchForm.querySelector('input').focus();
        }
    }
}
document.addEventListener('click', toggleSearch);
document.addEventListener('touchend', toggleSearch);

// Toggle account login/register dropdown
function toggleAccountDropdown(event) {
    if(dragging) return;

    var target = event.target.closest('.toggleAccount');
    if(target) {
        event.preventDefault();
        event.stopPropagation();

        target.nextElementSibling.classList.toggle("active");
    }
}
document.addEventListener('click', toggleAccountDropdown);
document.addEventListener('touchend', toggleAccountDropdown);

// Remove inline style on account dropdown and search to prevent "flash"
setTimeout(() => {
    let accountDropdown = document.querySelector(".accountDropdown")
	
	if (accountDropdown) {
		accountDropdown.removeAttribute("style");
	}
    document.querySelectorAll("form.siteSearch").forEach(form => form.removeAttribute("style"));
}, 200);