import { Api } from "./Api";

class OrderService {
    async requestCancelation(orderId, reason) {
        const data = {
            orderId,
            reason,
        };
        let res = await Api.post(
            "/actions/debrand/orders/request-cancellation",
            data
        );
        return res;
    }
}

export default new OrderService();
