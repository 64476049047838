function toggleRegisterSignInTabs(event) {
    let target = event.target.closest(".account.register-signin .wrapper .tabs button");
    if (target) {
        event.preventDefault()
        event.stopPropagation();
        
        let targetDataAttribute = target.getAttribute("data-button-title");
        
        document.querySelectorAll(".account.register-signin .wrapper .tabs button").forEach(button => {
            let activeButton = button.getAttribute("data-button-title");

            if (targetDataAttribute == activeButton) {
                button.classList.add("active");
            } else {
                button.classList.remove("active");
            }
        });
        document.querySelectorAll(".account.register-signin .wrapper .toggle-content").forEach(el => {
            let contentDataAttribute = el.getAttribute("data-content");
            if (targetDataAttribute == contentDataAttribute) {
                el.classList.add("active");
            } else {
                el.classList.remove("active");
            }
        });
    }
}

document.addEventListener("click", toggleRegisterSignInTabs);
document.addEventListener("touchend", toggleRegisterSignInTabs);
