export default () => ({
    addressModalOpen: false,
    addressSaving: false,
    container: null,

    init() {
        this.container = this.$el;
        var button = this.container.querySelector("form .submit-button");
        button.remove();

        window.addEventListener("addressSaving", (event) => {
            this.addressSaving = event.detail.addressSaving;
        });
    },

    openModal() {
        this.addressModalOpen = true;

        var addressModal = document.querySelector(
            ".modal-wrapper.address-modal-wrapper .modal"
        );
        if (addressModal) {
            addressModal.addEventListener("scroll", function () {
                document.activeElement.blur();
            });
        }

        setTimeout(() => {
            this.$dispatch("initAddressAutocomplete");
        }, 300);
    },

    async save($dialog) {
        this.$dispatch("saveAddress");
    },
});
