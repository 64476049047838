export class Address {
    constructor(el) {
        this._box = el;

        this._states = window.states;
        this._stateTypes = window.stateTypes;

        this._autocomplete;

        this.init();
    }

    refreshInputs() {
        this._businessNameContainer = this._box.querySelector(
            ".organization input"
        );
        this._address1Field = this._box.querySelector(".address-line-1 input");
        this._address2Field = this._box.querySelector(".address-line-2 input");
        this._postalField = this._box.querySelector(".postalCode input");
        this._cityField = this._box.querySelector(".locality input");
        this._stateFieldInput = this._box.querySelector(
            ".administrative-area input"
        );
        this._stateFieldSelect = this._box.querySelector(
            ".administrative-area select"
        );
        this._stateLabel = this._box.querySelector(
            ".administrative-area label"
        );
        this._countryField = this._box.querySelector(".country-code select");
    }

    init() {
        // this._addressType.addEventListener("change", () => {
        //     if (this._addressType.value == "Business") {
        //         this._businessNameContainer.classList.remove("hidden");
        //         return;
        //     }
        //     this._businessNameContainer.querySelector("input").value = "";
        //     this._businessNameContainer.classList.add("hidden");
        // });

        this.refreshInputs();
        this.initAutocomplete();

        window.addEventListener("initAddressAutocomplete", () => {
            this.refreshInputs();
            this.initAutocomplete();
        });
    }

    initAutocomplete() {
        // Listen for on initialization event
        if (!window.google) {
            console.warn("Google maps not loaded");
            return;
        }

        // Create the autocomplete object, restricting the search predictions to
        // addresses in the US and Canada.
        this._autocomplete = new google.maps.places.Autocomplete(
            this._address1Field,
            {
                fields: ["address_components"],
                types: ["address"],
            }
        );
        // this._address1Field.focus();

        // On page load the state dropdown has a data-selected attribute, but no value.
        // Value is only set if the user selects address from autocomplete field.
        // Check if value is empty, if so, set it to the data-selected value.
        if (this._stateFieldSelect && this._stateFieldSelect.value == "") {
            this._stateFieldSelect.value =
                this._stateFieldSelect.getAttribute("data-selected");
        }

        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        this._autocomplete.addListener("place_changed", () => {
            this.fillInAddress();
        });
    }

    fillInAddress() {
        // The states input and select could be dynamically hidden or shown.
        // Refresh the state input and select elements since they could be newly added.
        this.refreshInputs();

        // Get the place details from the autocomplete object.
        const place = this._autocomplete.getPlace();

        let address1 = "";
        let city = "";
        let state = "";
        let stateCode = "";
        let postcode = "";
        let country = "";
        let countryCode = "";

        if (!place.address_components) {
            return;
        }

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
            for (const componentType of component.types) {
                switch (componentType) {
                    case "street_number": {
                        address1 = `${component.long_name} ${address1}`;
                        break;
                    }
                    case "route": {
                        address1 += component.short_name;
                        break;
                    }
                    case "postal_code": {
                        postcode = `${component.long_name}${postcode}`;
                        break;
                    }
                    case "postal_code_suffix": {
                        postcode = `${postcode}-${component.long_name}`;
                        break;
                    }
                    case "postal_town":
                    case "locality":
                        city = component.long_name;
                        break;
                    case "administrative_area_level_1": {
                        state = component.long_name;
                        stateCode = component.short_name;
                        break;
                    }
                    case "country":
                        country = component.long_name;
                        countryCode = component.short_name;
                        break;
                }
            }
        }

        this.updateCountry(country, countryCode);
        this.updateLocalFields(address1, city, postcode);

        this._address2Field.focus();

        // Give time for the states dropdown to populate if needed
        setTimeout(() => {
            this.updateState(state, stateCode);
        }, 50);
    }

    updateCountry(country, countryCode) {
        this._countryField.querySelectorAll("option").forEach((option) => {
            if (option.innerText == country) {
                this._countryField.value = option.value;
                this._countryField.dispatchEvent(new Event("change"));
            }
        });
    }

    updateState(state, stateCode) {
        if (this._stateFieldSelect) {
            this._stateFieldSelect
                .querySelectorAll("option")
                .forEach((option) => {
                    if (option.innerText == state) {
                        this._stateFieldSelect.value = option.value;
                        this._stateFieldSelect.dispatchEvent(
                            new Event("change")
                        );
                    }
                });
        }

        if (this._stateFieldInput) {
            this._stateFieldInput.value = state;
            this._stateFieldInput.dispatchEvent(new Event("change"));
        }
    }

    updateLocalFields(address1, city, postalCode) {
        this._address1Field.value = address1;
        this._postalField.value = postalCode;
        this._cityField.value = city;

        // https://stackoverflow.com/a/36084475
        this._address1Field.dispatchEvent(new Event("change"));
        this._postalField.dispatchEvent(new Event("change"));
        this._cityField.dispatchEvent(new Event("change"));
    }
}

window.addEventListener("initAddressAutocomplete", function () {
    let addressBoxes = document.querySelectorAll(".js-address-fieldset");
    if (addressBoxes) {
        // Alpine will handle this
        // Array.from(addressBoxes).forEach((el) => new Address(el));
    }
});

window.initAutocomplete = function initAutocomplete() {
    window.dispatchEvent(new Event("initAddressAutocomplete"));
};
