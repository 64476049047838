function toggleLocations(event) {
    if (dragging) return;

    let target = event.target.closest(".block.locations .wrapper .buttons .rd-button");

    if (target) {
        event.preventDefault();
        event.stopPropagation();

        let buttonId = target.id;

        let locations = document.querySelectorAll(".block.locations .wrapper .location");
        let buttons = document.querySelectorAll(".block.locations .wrapper .buttons .rd-button");

        for (var i = 0; i < locations.length; i++) {
            let locationId = locations[i].id;

            if (buttonId === locationId) {
                locations[i].classList.add("active");
                buttons[i].classList.add("active");
            } else {
                locations[i].classList.remove("active");
                buttons[i].classList.remove("active");
            }
               
        }
    }
}

document.addEventListener("click", toggleLocations);
document.addEventListener("touchend", toggleLocations);