// Keep track of width in REMs
window.htmlStyles = window.getComputedStyle(document.getElementsByTagName("html")[0]);
window.widthREMs = window.innerWidth / parseFloat(htmlStyles.fontSize);
updateWidthREMS();
window.updateWidthREMS = updateWidthREMS;

/**
 *		- Updates the widthREMs variable on orientation change or resize (event listeners below)
 *		- Hides any toggle navigation if resizing greater than/equal to breakpointToDesktopNav
 */
function updateWidthREMS() {
	window.widthREMs = window.innerWidth / parseFloat(htmlStyles.fontSize);

    if (window.widthREMs >= window.breakpointToDesktopNav) {
        var toggleMenu = document.querySelector('.toggleMenu');
        if (toggleMenu) {
            toggleMenu.classList.remove("on");
        }
        var navsWithChildren = document.querySelectorAll(".siteNav .siteMenu ul li");
        for (var i = 0; i < navsWithChildren.length; i++) {
            navsWithChildren[i].classList.remove("on");
            if(navsWithChildren[i].querySelector(".siteNav .siteMenu .childNav")) {
                navsWithChildren[i].querySelector(".siteNav .siteMenu .childNav").style.display = "none";
            }
        }
    }
}

/**
 *		- Call updateWidthREMS on orientationchange and resize events
 */
window.addEventListener("orientationchange", updateWidthREMS);
window.addEventListener("resize", updateWidthREMS);