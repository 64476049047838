import CartService from "../../services/CartService";

export default () => ({
    cart: {
        lineItems: [],
        orderAdjustments: [],
    },
    removing: [],
    updating: [],

    filterLineItemId: "",

    hasFetchedCart: false,
    isFetchingCart: false,

    init() {
        this.isFetchingCart = true;

        const queryParams = new URLSearchParams(window.location.search);
        let number = queryParams.get("number");
        if (window.location.pathname.indexOf("/account/orders/") > -1) {
            number = window.location.pathname.split("/").pop();
        }

        CartService.getCart(this, number);

        window.addEventListener("cartUpdate", (event) => {
            if (number && number != event.detail.number) {
                return;
            }
            this.cart = event.detail;
            this.hasFetchedCart = true;
            this.isFetchingCart = false;
        });
    },

    async updateQuantities(lineItemId) {
        try {
            this.updating.push(lineItemId);

            const lineItem = this.cart.lineItems.filter(
                (li) => li.id == lineItemId
            )[0];

            await CartService.updateLineItem(this, lineItemId, lineItem.qty);
        } catch (e) {}
        this.updating = this.updating.filter((u) => u != lineItemId);
    },

    async removeLineItem(lineItemId) {
        try {
            this.removing.push(lineItemId);
            const res = await CartService.removeLineItem(this, lineItemId);
            this.refreshData(res.data.cart);
            var lineItems = document.querySelectorAll(
                ".line-item-" + lineItemId
            );
            if (lineItems) {
                lineItems.forEach((e) => e.remove());
            }
        } catch (e) {
            console.error(e);
            this.removing = this.removing.filter((i) => i != lineItemId);
        }
    },
});
