import { Api } from "../../services/Api";

export default () => ({
    loading: false,
    reset() {
        this.loading = false;
    },
    async removeGiftCard(maskedGiftCardNumber) {
        console.log(maskedGiftCardNumber);
        if (!confirm("Are you sure you want to remove this gift card?")) {
            return;
        }
        this.reset();
        this.loading = true;
        try {
            await Api.post("/actions/redfire-giftcards/cart/remove-gift-card", {
                maskedGiftCardNumber,
            });
            window.location.reload();
        } catch (e) {
            window.location.reload();
        }
    },
});
