import axios from "axios";
export const Api = axios.create({
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
});

// Automatically Apply CSRF tokens
Api.interceptors.request.use(async (request) => {
    try {
        if (request.method.toLowerCase() !== "get") {
            const res = await Api.get("/actions/users/session-info");
            const session = res.data;
            request.headers["X-CSRF-Token"] = session.csrfTokenValue;

            if (request.data instanceof FormData) {
                request.headers["Content-Type"] = "multipart/form-data";
            }
        }
        return request;
    } catch (err) {
        throw new Error(
            `axios# Problem with request during pre-flight phase: ${err}.`
        );
    }
});
