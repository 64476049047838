import { Api } from "../../services/Api";

export default () => ({
    eGiftCardSaving: false,
    eGiftCardForm: {},
    form: null,
    purchasableId: "",
    lineItemId: null,
    init() {
        this.form = document.querySelector("form[name=GiftCardForm]");
        // Handle confirm selections from buy again in order history
        const searchParams = new URLSearchParams(window.location.search);
        this.purchasableId = searchParams.get("variant");
        this.lineItemId = searchParams.get("lineItemId");

        this.eGiftCardForm.giftDeliveryDate = new Date()
            .toISOString()
            .split("T")[0];

        const options = searchParams.get("options");
        if (options) {
            try {
                const decoded = atob(options);
                const parsed = JSON.parse(decoded);

                this.eGiftCardForm.giftAmount = parsed.amount;
                this.eGiftCardForm.giftRecipientEmail = parsed.recipientEmail;
                this.eGiftCardForm.confirmRecipientEmail =
                    parsed.recipientEmail;
                this.eGiftCardForm.giftRecipientName = parsed.recipientName;
                this.eGiftCardForm.giftFromName = parsed.fromName;
                this.eGiftCardForm.giftMessage = parsed.giftMessage;
                this.eGiftCardForm.giftDeliveryDate = parsed.deliveryDate;
            } catch (e) {
                console.error(e);
            }
        }
    },

    initGiftCards(giftCards) {
        const searchParams = new URLSearchParams(window.location.search);
        giftCards.forEach((variant) => {
            let id = searchParams.get("variant") || "";
            if (variant.id == id) {
                this.purchasableId = id;
            }
        });
    },

    async addToCart(e) {
        try {
            if (this.eGiftCardSaving) {
                return;
            }

            if (!this.purchasableId) {
                alert("Please select a gift card design.");
                return;
            }

            var form = this.form;

            if (form.reportValidity) {
                form.reportValidity();
            }

            if (form.checkValidity && form.checkValidity() == false) {
                return;
            }

            this.eGiftCardSaving = true;

            let options = {
                giftCard: {
                    amount: this.eGiftCardForm.giftAmount,
                    recipientEmail: this.eGiftCardForm.giftRecipientEmail,
                    recipientName: this.eGiftCardForm.giftRecipientName,
                    fromName: this.eGiftCardForm.giftFromName,
                    deliveryDate: this.eGiftCardForm.giftDeliveryDate,
                },
            };

            let data = {
                purchasableId: this.purchasableId,
                qty: 1,
                note: this.eGiftCardForm.giftMessage,
                options,
            };

            if (!!this.lineItemId) {
                // Check if we're changing the Gift Card style.
                if (this.lineItemId != this.purchasableId) {
                    // Remove the existing Gift Card
                    const res = await Api.post(
                        "/actions/commerce/cart/update-cart",
                        {
                            lineItems: {
                                [this.lineItemId]: {
                                    remove: true,
                                    purchasableId: this.purchasableId,
                                },
                            },
                        }
                    );
                    if (res.data.errors) {
                        return;
                    }
                } else {
                    // Update the line item.
                    data = {
                        lineItems: {
                            [this.lineItemId]: {
                                purchasableId: this.purchasableId,
                                options,
                                qty: 1,
                                note: this.eGiftCardForm.giftMessage,
                            },
                        },
                    };
                }
            }

            const res = await Api.post(
                "/actions/commerce/cart/update-cart",
                data
            );
            if (res.data.errors) {
                // TODO:
                return;
            }
            this.form.reset();

            window.location.href = "/shop/cart";
        } catch (e) {
            console.error(e);
            this.eGiftCardSaving = false;
            this.formError = "Something went wrong.";
        }
    },
});
