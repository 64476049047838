import { Api } from "../../services/Api";

export default () => ({
    accountModalOpen: false,
    accountSaving: false,
    accountSession: {},
    init() {
        Api.get("/actions/users/session-info").then((res) => {
            this.accountSession = res.data;

            var input = document.querySelectorAll(
                'input[name="CRAFT_CSRF_TOKEN"]'
            );

            for (var i = 0; i < input.length; i++) {
                input[i].value = res.data.csrfTokenValue;
            }
        });
    },
});
