export default () => ({
    productFilterSliderOpen: false,

    // init() {

    // },

    openProductFilterSlider() {
        this.productFilterSliderOpen = true;
    },
});
