function toggleActiveGallerySlide(event) {
	if (dragging) return;

	var target = event.target.closest(".block.gallery .nav > a");
	if (target) {
		event.preventDefault();

		var gallery = target.closest(".block.gallery");
		gallery.querySelector(".slider .slide.active").classList.remove("active");
		gallery.querySelector(".slider .slide[data-slide='" + target.getAttribute("data-slide") + "']").classList.add("active");
	}
}

document.addEventListener("click", toggleActiveGallerySlide);
document.addEventListener("touchend", toggleActiveGallerySlide);