import { Api } from "./Api";

class CartService {
    transformCart(cart) {
        // cart.orderAdjustments.filter(a => a.type != "tax" && a.type != "shipping")

        const formatter = new Intl.NumberFormat(cart.orderLanguage, {
            style: "currency",
            currency: cart.currency,
        });
        cart.giftCardSubtotal = cart.orderAdjustments
            .filter((a) => a.type == "redfire-giftcard")
            .map((a) => a.amount)
            .reduce((carry, v) => carry + v, 0);

        cart.giftCardSubtotalAsCurrency = formatter.format(
            cart.giftCardSubtotal
        );

        cart.totalDiscountAsCurrency = formatter.format(cart.totalDiscount);
        cart.totalPriceAsCurrency = formatter.format(cart.totalPrice);

        return cart;
    }
    async getCart(el, number = "") {
        let url = "/actions/debrand/cart/get-cart";
        if (number) {
            url += "?number=" + number;
        }
        const res = await Api.get(url);
        const cart = this.transformCart(res.data.cart);
        el.$dispatch("cartUpdate", cart);
        return this.transformCart(cart);
    }

    async updateLineItem(el, lineItemId, qty) {
        const data = {
            lineItems: {
                [lineItemId]: {
                    qty,
                },
            },
        };
        await Api.post("/actions/commerce/cart/update-cart", data);
        await this.getCart(el);
        return res;
    }

    async removeLineItem(el, lineItemId) {
        const data = {
            lineItems: {
                [lineItemId]: {
                    remove: 1,
                },
            },
        };
        const res = await Api.post("/actions/commerce/cart/update-cart", data);
        if (res.data.errors) {
            // TODO:
            return;
        }

        await this.getCart(el);
        return res;
    }

    async updateCartPaymentGateway(el, activeGatewayId) {
        const data = {
            gatewayId: activeGatewayId,
            paymentSourceId: null,
        };
        const res = await Api.post("/actions/commerce/cart/update-cart", data);
        await this.getCart(el);
        return res;
    }

    async updateCartPaymentSourceId(el, paymentSourceId) {
        const data = {
            paymentSourceId,
        };
        const res = await Api.post("/actions/commerce/cart/update-cart", data);
        await this.getCart(el);
        return res;
    }

    async removePaymentSource(el, paymentSourceId) {
        const data = {
            id: paymentSourceId,
        };
        const res = await Api.post(
            "/actions/commerce/payment-sources/delete",
            data
        );
        return res;
    }
}

export default new CartService();
