function toggleJobPostings(event) {
    if (dragging) return;

    let target = event.target.closest(".block.jobPostings .wrapper .buttons .rd-button");

    if (target) {
        event.preventDefault();
        event.stopPropagation();

        let buttonId = target.id;

        let jobPostings = document.querySelectorAll(".block.jobPostings .wrapper .jobPosting");
        let buttons = document.querySelectorAll(".block.jobPostings .wrapper .buttons .rd-button");

        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].id === buttonId) {
                buttons[i].classList.add("active");
            } else {
                buttons[i].classList.remove("active");
            }
        }

        if (buttonId == 'all') {
            for (var i = 0; i < jobPostings.length; i++) {
                jobPostings[i].classList.add("active");
            }
        } else {
            for (var i = 0; i < jobPostings.length; i++) {
                // for each job posting that has the buttonId as part of the data-category attribute, add the active class
                if (jobPostings[i].dataset.category.includes(buttonId)) {
                    jobPostings[i].classList.add("active");
                } else {
                    jobPostings[i].classList.remove("active");
                }
            }
        }
    }
}

document.addEventListener("click", toggleJobPostings);
document.addEventListener("touchend", toggleJobPostings);