import { Api } from "../../services/Api";

import Choices from "choices.js";

export default () => ({
    orders: [],
    element: null,
    submitting: false,
    form: null,
    products: [],
    thoughtBar: null,
    recipientList: null,
    customImage: null,
    customImageData: null,
    comments: null,
    message: null,
    mode: "add",
    multiple: true,
    barOptions: {},
    options: [],
    choices: null,
    dropdownShown: false,
    selectedOrder: null,
    isThoughtBarCustom: false,
    customThoughtBarIds: [],
    uploading: false,
    init() {
        this.element = this.$el;
        this.form = this.$el.querySelector("form");
        this.options = this.$store.corporateOrderData.products;
        this.$nextTick(() => {
            this.initChoices();
        });
    },
    initChoices() {
        const productDropdown = this.form.querySelector("#product");
        this.choices = new Choices(productDropdown, {
            placeholder: true,
            removeItemButton: true,
            allowHTML: true,
        });

        productDropdown.addEventListener("showDropdown", () => {
            this.dropdownShown = true;
        });

        productDropdown.addEventListener("hideDropdown", () => {
            this.dropdownShown = false;
        });

        productDropdown.addEventListener("change", () => {
            this.products = this.choices.getValue(true);
        });

        this.refreshChoices();
        this.$watch("products", () => this.refreshChoices());
        this.$watch("options", () => this.refreshChoices());
    },
    refreshChoices() {
        let selection = this.multiple ? this.products : [this.products];
        this.choices.clearStore();
        this.choices.setChoices([
            ...this.options.map(({ value, label }) => ({
                value,
                label,
                selected: selection.includes(value),
            })),
        ]);
    },
    handleSubmit(event) {
        var self = this;
        event.preventDefault();

        var form = this.form;

        var formData = new FormData(this.form);

        if (formData.get("ship-date") == "") {
            alert("Please choose a desired ship date.");
            this.scrollIntoView("ship-date");
            return;
        }

        if (form.reportValidity) {
            form.reportValidity();
        }

        if (form.checkValidity && form.checkValidity() == false) {
            console.log("Form is invalid");
            return;
        }

        this.submitting = true;

        // Loop through the orders and upload the recipientLists as a file
        this.orders.forEach((order, index) => {
            if (order.recipientList) {
                formData.append(`recipientList-${index}`, order.recipientList);
            }
        });

        // Loop through the orders and upload the customImage as a file
        this.orders.forEach((order, index) => {
            if (order.customImage) {
                formData.append(`customImage-${index}`, order.customImage);
            }
        });

        // formData.append("document", documentJson); instead of this, use the line below.
        formData.append(
            "batches",
            JSON.stringify(
                this.orders.map((order) => ({
                    ...order,
                    products: order.products.map((product) => product.id),
                }))
            )
        );

        axios({
            method: "post",
            url: this.form.action,
            data: formData,
        })
            .then(function (response) {
                window.location.href = response.data.redirect;
            })
            .catch(function (err) {
                console.error(err);
                const response = err.response;
                self.submitting = false;
                if (response.data && response.data.message) {
                    alert(response.data.message);
                } else {
                    alert("An error occurred while submitting your order.");
                }
            });
    },
    appendOrder() {
        var form = this.form;

        if (form.reportValidity) {
            form.reportValidity();
        }

        if (this.products.length === 0) {
            alert("Please select a product to add to your order.");
            this.scrollIntoView("step-3");
            return;
        }

        if (this.message.length > 250) {
            alert("Please limit your gift message to 250 characters.");
            this.scrollIntoView("step-3");
            return;
        }

        if (form.checkValidity && form.checkValidity() == false) {
            return;
        }

        let newOrder = {
            id: Math.random(),
            products: this.products.map((id) => {
                let product = this.options.find((product) => product.id == id);
                return { ...product };
            }),
            thoughtBar: this.thoughtBar,
            isThoughtBarCustom: this.isThoughtBarCustom,
            recipientList: this.recipientList,
            customImage: this.customImage,
            comments: this.comments,
            barOptions: this.barOptions,
            message: this.message,
        };

        this.orders = [...this.orders, newOrder];
        this.products = [];
        this.thoughtBar = null;
        this.recipientList = null;
        this.customImage = null;
        this.comments = null;
        this.message = null;
        this.isThoughtBarCustom = false;
        this.mode = "preview";

        this.refreshChoices();

        this.scrollIntoView("step-3");

        this.selectedOrder = this.orders.length - 1;
    },

    reset(mode) {
        this.selectedOrder = this.orders.length;
        this.mode = mode;
        this.products = [];
        this.thoughtBar = null;
        this.recipientList = null;
        this.barOptions = {};
        this.customImage = null;
        this.comments = null;
        this.isThoughtBarCustom = false;
        this.message = null;

        this.$nextTick(() => {
            this.initChoices();
            this.checkCustomThoughtBar();
        });
    },

    startAnotherOrder() {
        this.reset("add");
    },

    cancel() {
        this.reset("preview");
    },

    edit(index) {
        let selectedProducts = this.orders[index].products.map(
            (product) => product.id
        );
        let order = this.orders[index];
        this.selectedOrder = index;
        this.mode = "edit";
        this.thoughtBar = order.thoughtBar;
        this.recipientList = order.recipientList;
        this.customImage = order.customImage;
        this.barOptions = order.barOptions;
        this.comments = order.comments;
        this.message = order.message;
        this.isThoughtBarCustom = false;
        this.products = selectedProducts;

        this.$nextTick(() => {
            this.checkCustomThoughtBar();
            this.initChoices();
        });

        this.scrollIntoView("step-3");
    },

    save() {
        if (this.products.length === 0) {
            alert("Please select a product to add to your order.");
            this.scrollIntoView("step-3");
            return;
        }

        if (this.message.length > 250) {
            alert("Please limit your gift message to 250 characters.");
            this.scrollIntoView("step-3");
            return;
        }

        var form = this.form;

        if (form.reportValidity) {
            form.reportValidity();
        }

        if (form.checkValidity && form.checkValidity() == false) {
            return;
        }

        this.orders[this.selectedOrder]["products"] = this.products.map(
            (id) => {
                let product = this.options.find((product) => product.id == id);
                return { ...product };
            }
        );
        this.orders[this.selectedOrder]["thoughtBar"] = this.thoughtBar;
        this.orders[this.selectedOrder]["barOptions"] = this.barOptions;
        this.orders[this.selectedOrder]["isThoughtBarCustom"] =
            this.isThoughtBarCustom;
        this.orders[this.selectedOrder]["comments"] = this.comments;
        this.orders[this.selectedOrder]["message"] = this.message;
        this.orders[this.selectedOrder]["recipientList"] = this.recipientList;
        this.orders[this.selectedOrder]["customImage"] = this.customImage;

        this.mode = "preview";

        this.scrollIntoView("step-3");
    },

    remove(index) {
        if (
            prompt(
                "Are you sure you want to delete this order? Please type REMOVE to confirm."
            ).toUpperCase() !== "REMOVE"
        ) {
            return;
        }

        this.orders.splice(index, 1);

        if (this.orders.length === 0) {
            this.mode = "add";
            this.selectedOrder = 0;
        }

        this.reset(this.mode);

        this.scrollIntoView("step-3");
    },

    scrollIntoView(elementId) {
        let element = document.getElementById(elementId);

        if (element) {
            this.$nextTick(() => {
                element.scrollIntoView();
            });
        }
    },

    checkCustomThoughtBar(event = null) {
        this.isThoughtBarCustom =
            this.customThoughtBarIds.indexOf(parseInt(this.thoughtBar)) > -1;
    },

    handleCustomImage(event) {
        let file = event.target.files[0];

        try {
            this.validateImage(file);
        } catch (error) {
            console.error(error);
            document.getElementById("custom-image").value = null;
            alert(error.message);
            return;
        }

        this.customImage = file;
    },

    handleCustomImageOld(event) {
        let file = event.target.files[0];

        try {
            this.validateImage(file);
        } catch (error) {
            console.error(error);
            document.getElementById("custom-image").value = null;
            alert(error.message);
        }

        this.customImageData = file;
        let formData = new FormData();
        formData.append("customLogo", file);
        this.uploading = true;

        Api.post(
            "/actions/debrand/corporate-orders/upload-custom-logo",
            formData
        )
            .then((res) => {
                this.customImage = res.data.filename;
                this.uploading = false;
                this.customImage = res.data.filename;
            })
            .catch((error) => {
                console.error(error);
            });
    },

    handleRecipientList(event) {
        let file = event.target.files[0];
        this.recipientList = file;
    },

    validateImage(file) {
        const maxFileSize = 5000000;
        const allowedFileTypes = [
            "application/postscript",
            "image/webp",
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/gif",
        ];

        if (!file) {
            throw Error("Please select a file to upload");
        }

        // Validate file type
        if (!allowedFileTypes.includes(file.type)) {
            throw Error(
                "Please select a file type of .jpeg, .jpg, .png, .webp, .gif"
            );
        }

        // Validate file size
        if (file.size > maxFileSize) {
            throw Error(
                "The file you selected exceeds the max file size of " +
                    maxFileSize
            );
        }
    },
});
