import flatpickr from "flatpickr";

export default () => ({
    value: ["today"],
    picker: null,

    initFlatPickr(options = {}) {
        this.picker = flatpickr(this.$refs.picker, {
            minDate: "today",
            enableTime: false,
            maxDate: new Date().fp_incr(90),
            defaultDate: this.value,
            ...options,
        });
        this.$watch("value", () => this.picker.setDate(this.value));
    },
});
