/**
 *		IS ELEMENT IN VIEWPORT
 *		- Returns "false" only if completely outside of viewport, otherwise...
 *		- "contain" if completely in viewport
 *		- "cover" if completely envelopes the viewport
 *		- "partial" if any portion of the element is within the viewport
 */
function isElementInViewport(el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	if (!document.body.contains(el)) {
		return false;
	}

	var rect = el.getBoundingClientRect();

	if (rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {

		// Element fits completely in viewport
		return "contain";

	} else
	if (rect.top <= 0 &&
		rect.left <= 0 &&
		rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right >= (window.innerWidth || document.documentElement.clientWidth)) {

		// Viewport fits completely within element
		return "cover";

	} else
	if (rect.top >= (window.innerHeight || document.documentElement.clientHeight) ||
		rect.left >= (window.innerWidth || document.documentElement.clientWidth) ||
		rect.bottom <= 0 ||
		rect.right <= 0) {

		// Any side is beyond opposite viewport limit (top side is below bottom, left side is beyond right, bottom is above top, or right is beyond left)
		return false;

	} else {

		// Only remaining option: some portion of element is within viewport, but not all of it
		return "partial";

	}
}
window.isElementInViewport = isElementInViewport;